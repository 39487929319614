#login-section {
    background: linear-gradient(90deg, #fe7d5e, #fe7d5e 35%, #ffa774);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    position: relative;
}

.login-mid {
    margin: auto;
    max-width: 80rem;
    width: 100%;
}

.login-mk {
    padding-left: 30px;
    padding-right: 30px;
}

.login-box {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    gap: 85px;
    justify-content: space-between;
    background: #fff;
    padding: 20px;
}

.login-box,
.login-box-left {
    position: relative;
    display: flex;
}

.login-box-left {
    width: 50%;
    align-items: center;
    padding: 0;
}

.login-box-left img {
    border-radius: 26px;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
}

.home-drbox {
    position: absolute;
    margin: 10px;
    width: 90%;
    border-radius: 10px;
    inset: auto 0 0;
    z-index: 1;
    padding: 15px;
    display: block;
    background: rgba(0, 0, 0, .12);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    left: 25px;
    bottom: 30px;
}

.home-drbox h4 {
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    text-align: left;
}

.home-drbox ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.home-drbox li {
    flex: 0 0 32.33%;
    align-items: center;
    text-align: left;
    justify-content: left;
    padding: 0 15px 15px;
}

.home-drbox li img {
    width: 20px;
    height: 20px;
    border-radius: 0;
    margin-bottom: 10px;
}

.home-drbox li h5 {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    width: 100%;
    margin: 0 auto;
}

.login-box-right {
    width: 40%;
    margin-right: 10%;
    display: flex;
    padding: 0;
    flex-direction: column;
}

.login-box-right h1 {
    font-size: 30px;
    color: #090909;
    font-weight: 400;
    width: 100%;
    margin: 40px 0 0;
    font-family: Helvetica Neue Medium;
}

.login-box-right .inputbox {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
}

.login-box-right label {
    font-family: Helvetica Neue Medium;
    font-size: 14px;
    color: #575757;
}

.login-box-right input {
    border: 1px solid #dcdee2;
    height: 45px;
    padding-left: 15px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    border-radius: 6px;
    background-color: #fff;
    outline: none;
    text-align: left;
}

.password-input-container {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px;
    height: auto;
}

.login-box-right .login-button {
    background: #ff6800;
    color: #fff !important;
    border: 2px solid #ff6800;
    margin: 20px 0;
    font-family: Helvetica Neue Medium;
    font-size: 22px;
    border-radius: 8px;
    padding: 12px;
    position: relative;
    width: 100%;
    text-align: center;
}

.account-box {
    font-size: 18px;
    color: #7c7c7c;
    width: 100%;
    text-align: center;
    margin: 10px 0 0;
    padding: 0;
}

.account-box a {
    color: #ff6800 !important;
}

.login-box-left {
    width: 50%;
    align-items: center;
    padding: 0;
}

.login-img {
    display: none;
}

.login-box-left img {
    border-radius: 26px;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
}

.home-drbox {
    position: absolute;
    margin: 10px;
    width: 90%;
    border-radius: 10px;
    inset: auto 0 0;
    z-index: 1;
    padding: 15px;
    display: block;
    background: rgba(0, 0, 0, .12);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    left: 25px;
    bottom: 30px;
}

.home-drbox h4 {
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    text-align: left;
}

.home-drbox ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.home-drbox li {
    flex: 0 0 32.33%;
    align-items: center;
    text-align: left;
    justify-content: left;
    padding: 0 15px 15px;
}

.home-drbox li img {
    width: 20px;
    height: 20px;
    border-radius: 0;
    margin-bottom: 10px;
}

.home-drbox li h5 {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    width: 100%;
    margin: 0 auto;
}


@media screen and (max-width: 880px) {
    #login-section {
        height: 100%;
        padding: 20px;
    }

    .login-mk {
        padding-left: 10px;
        padding-right: 10px;
    }

    .login-mid {
        max-width: 100%;
    }

    .login-box {
        flex-direction: column;
    }

    .login-img-d {
        display: none;
    }

    .login-box-left,
    .login-box-right {
        flex-direction: column;
        width: 100%;
    }

    .home-drbox {
        left: 5px;
        bottom: 5px;
    }

    .home-drbox h4 {
        font-size: 20px;
    }

    .home-drbox li {
        flex: 0 0 50%;
        padding: 5px;
    }

    .home-drbox li h5 {
        font-size: 14px;
    }

    .login-box-right {
        margin-right: 0;
        margin-top: 2rem;
    }

    .login-box-right h1 {
        font-size: 22px;
        margin: 20px 0 0;
    }
}