.lead-search-input {
    padding: 8px;
    border: 1px solid #ccc; /* Light border for inputs */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease; /* Smooth border transition */
  }
  
  .lead-search-input:focus {
    border-color: #ff6800; /* Change border color on focus */
  }
  
  .lead-search-button {
    padding: 10px 20px;
    background-color: #ff6800; /* Button background color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .lead-search-button:hover {
    background-color: #e55f00; /* Darken on hover */
  }
  
  .lead-search-button:focus {
    outline: none;
  }