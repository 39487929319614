.visa-form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .visa-form-box {
    margin-bottom: 20px;
  }
  
  .visa-form-box label {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
  }
  
  .visa-form-box select,
  .visa-form-box input {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .visa-form-box select:focus,
  .visa-form-box input:focus {
    border-color: #ff6800;
  }
  
  .visa-submit {
    width: 100%;
    padding: 12px 16px;
    background-color: #ff6800;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .visa-submit:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .visa-submit:hover:not(:disabled) {
    background-color: #e65c00;
  }
  
  .visa-submit:active {
    transform: scale(0.98);
  }
  