.appointment-form-container {
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .appointment-form-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .appointment-form .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .form-input,
  .form-select,
  .form-textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-textarea {
    height: 80px;
    resize: vertical;
  }
  
  .form-submit-btn {
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form-submit-btn:hover {
    background-color: #218838;
  }
  