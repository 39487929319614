.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.search-results ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.search-results li {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-results li:last-child {
    border-bottom: none;
}

.search-results li:hover {
    background-color: #ff6800;
    color: #fff;
}

.search-results p {
    text-align: center;
    padding: 10px;
    color: #666;
}

.loading-message {
    padding: 10px;
    color: #ff6800;
    font-style: italic;
    text-align: center;
}

.search-results a {
    color: #333;
    text-decoration: none;
    display: block;
    width: 100%;
}

.search-results a:hover {
    color: #fff;
    text-decoration: underline;
}